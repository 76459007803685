<template>
    <div style="position: relative">
        <div id="J_prismPlayer"></div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props:{
        url:String
    },
    data(){
        return {
        }
    },
    created(){
    },
    mounted(){
        this.startVideo(this.$props.url)
    },
    methods:{
        startVideo(url){
            let self = this
            axios.get(window.apiUrl.sts).then(res=>{
                let info = res.data.data.credentials
                let accessKeyId = info.accessKeyId
                let accessKeySecret = info.accessKeySecret
                let securityToken = info.securityToken
                
                // let videoId = '70d4c72e7a1371efbd640764b3ec0102'
                setTimeout(function(){
                    self.getStartVideo(url,accessKeyId,securityToken,accessKeySecret)
                },500)   
            })
            
        },
        getStartVideo(videoId,accessKeyId,securityToken,accessKeySecret){
            self.aliplayer = new window.Aliplayer({
                id: 'J_prismPlayer',
                width: '100%',
                vid : videoId, // 必选参数。音视频ID可以在音视频上传完成后通过控制台（路径：媒资库 > 音/视频。）或服务端接口（SearchMedia ）获取。示例：1e067a2831b641db90d570b6480f****。
                accessKeyId: accessKeyId, // 必选参数。STS临时AK对的访问密钥ID，生成STS安全令牌时返回。
                securityToken: securityToken, // 必选参数。STS安全令牌，需要调用STS服务的AssumeRole接口生成。
                accessKeySecret: accessKeySecret, // 必选参数。STS临时AK对的访问密钥，生成STS安全令牌时返回。
                region: 'cn-beijing', // 必选参数。媒体资源所在的地域标识。如cn-shanghai、eu-central-1, ap-southeast-1等。
                autoplay: false,
                // authTimeout: 7200, // 播放地址的有效时长，单位：秒。该时长会覆盖在视频点播控制台设置的URL鉴权的有效时长。如果不传，则取默认值7200。如需设置此参数，请确保该时间大于视频的实际时长，防止播放地址在播放完成前过期。
            },function(player){
                console.log('The player is created.')
            }); 
        },
    },
}
</script>

<style scoped lang="less">

</style>